// import { Translatable } from "src/app/shared/model/translatable.model";

import { Show } from '../../show/model/show.model';
import { Organization } from '../../organization/model/organization.model';
import { Venue } from '../../venue/model/venue.model';
import { ProgramStatus } from './program-status.model';
import { getClosestDay, setHoursToDate } from 'src/app/shared/utils/time-utils';
import { ICreatedResponse } from '@app/shared/model/response.model';
export interface IProgramCreatedResponse extends ICreatedResponse {
    program: IProgram;
    isCreatedProgramCalendar?: boolean;
}

export interface ISearchProgramCalendar {
    show: string;
    artist: string;
    venue: string;
    producer: string;
    diffusers: string;
    date?: number;
}

export interface VenueMatch {
    id: number;
    isActive: number;
    photo: string;
    statusId: number;
}

export interface Match {
    id: number;
    showId: number;
    showCustom: string;
    organizationId: number;
    venueId: number;
    venueCustom: string;
    venue: VenueMatch;
    date: Date;
    hour: Date;
    isProducer: boolean;
    isDiffuser: boolean;
    statusId: ProgramStatus;
    note: string;
    userId: number;
    diffuserId: number;
    organization: Organization;
    isMatchUpdated: boolean;
    isMatchDeleted: boolean;
}
export interface IProgram {
    id?: number;
    showId?: number;
    show?: Show;
    showCustom?: string;
    organizationId: number;
    organization: Organization;
    venueId?: number;
    venue?: Venue;
    venueCustom?: string;
    date?: Date;
    hour?: Date;
    isProducer?: number;
    isTourArchivedOrClosedForDeposit?: number;
    isDiffuser?: number;
    statusId?: number;
    tourId?: number;
    note?: string;
    userId?: number; // Remove Array
    calendarId?: number; // Remove Array
    allCalendars?: number[]; // Remove Array
    diffuserId?: number;
    match?: Match;
    isMatchUpdated: boolean;
    isMatchDeleted: boolean;
    isAccepted?: boolean;
    isTourAdmin?: boolean;
    timezoneId?: number;
}
export class Program implements IProgram {
    // export class Program extends Translatable {
    id?: number;
    showId?: number;
    show?: Show;
    showCustom?: string;
    organizationId: number;
    organization: Organization;
    venueId?: number;
    venue?: Venue;
    venueCustom?: string;
    date?: Date;
    hour?: Date;
    isProducer?: number;
    isTourArchivedOrClosedForDeposit?: number;
    isDiffuser?: number;
    statusId?: number;
    tourId?: number;
    note?: string;
    userId?: number; // Remove Array
    calendarId?: number; // Remove Array
    allCalendars?: number[]; // Remove Array
    diffuserId?: number;
    diffuser?: Organization;
    match?: Match;
    isMatchUpdated: boolean;
    isMatchDeleted: boolean;
    isAccepted?: boolean;
    isTourAdmin?: boolean;
    timezoneId?: number;

    constructor(data?: Record<string, any>) {
        this.id = data[ 'id' ] ? data[ 'id' ] : undefined;
        this.showId = data[ 'showId' ] ? data[ 'showId' ] : undefined;
        this.show = data[ 'show' ] ? new Show(data[ 'show' ]) : undefined;
        this.showCustom = data[ 'showCustom' ] ? data[ 'showCustom' ] : undefined;
        this.organizationId = data[ 'organizationId' ] ? data[ 'organizationId' ] : undefined;
        this.organization = data[ 'organization' ] ? new Organization(data[ 'organization' ]) : undefined;
        this.venue = data[ 'venue' ] ? new Venue(data[ 'venue' ]) : undefined;
        this.venueId = data[ 'venueId' ] ? data[ 'venueId' ] : undefined;
        this.venueCustom = data[ 'venueCustom' ] ? data[ 'venueCustom' ] : undefined;
        this.date = data[ 'date' ] ? getClosestDay(data[ 'date' ]) : null;
        // ? NOTE: hours format from GET request is "HH:mm:ss" but must be converted to a Date object to be used in a time picker
        this.hour = data[ 'hour' ] ? setHoursToDate(data[ 'hour' ]) : null;
        this.isProducer = data[ 'isProducer' ] ? data[ 'isProducer' ] : undefined;
        this.isTourArchivedOrClosedForDeposit = data[ 'isTourArchivedOrClosedForDeposit' ];
        this.isDiffuser = data[ 'isDiffuser' ] ? data[ 'isDiffuser' ] : undefined;
        this.statusId = data.statusId;
        this.tourId = data[ 'tourId' ] ? data[ 'tourId' ] : undefined;
        this.note = data[ 'note' ] ? data[ 'note' ] : undefined;
        this.userId = data[ 'userId' ] ? data[ 'userId' ] : undefined;
        this.calendarId = data[ 'calendarId' ] ? data[ 'calendarId' ] : undefined;
        this.allCalendars = data[ 'allCalendars' ] ? data[ 'allCalendars' ] : [];
        this.diffuserId = data[ 'diffuserId' ];
        this.diffuser = data[ 'diffuser' ] ? new Organization(data[ 'diffuser' ]) : undefined;
        this.isMatchUpdated = data[ 'isMatchUpdated' ] != null ? !!data[ 'isMatchUpdated' ] : undefined;
        this.isMatchDeleted = data[ 'isMatchDeleted' ] != null ? !!data[ 'isMatchDeleted' ] : undefined;
        this.isAccepted = data[ 'isAccepted' ] != null ? !!data[ 'isAccepted' ] : undefined;
        this.isTourAdmin = data[ 'isTourAdmin' ] ? data[ 'isTourAdmin' ] : false;
        this.timezoneId = data[ 'timezoneId' ] ? data[ 'timezoneId' ] : undefined;
        if (data[ 'match' ]) {
            this.match = {
                ...data[ 'match' ],
                date: data[ 'match' ].date && getClosestDay(new Date(data[ 'match' ].date)),
                // cf. NOTE l.118 ("this.hour = "")
                hour: data[ 'match' ].hour && setHoursToDate(data[ 'match' ].hour),
                isProducer: data[ 'match' ].isProducer != null ? !!data[ 'match' ].isProducer : undefined,
                isDiffuser: data[ 'match' ].isProducer != null ? !!data[ 'match' ].isDiffuser : undefined,
                organization: data[ 'match' ].organization && new Organization(data[ 'match' ].organization),
                isMatchUpdated: data[ 'match' ].isMatchUpdated != null ? !!data[ 'match' ].isMatchUpdated : undefined,
                isMatchDeleted: data[ 'match' ].isMatchDeleted != null ? !!data[ 'match' ].isMatchDeleted : undefined
            };
        }
    }

    toJSON() {
        return {
            ...this,
            isAccepted: this.isAccepted != null ? +this.isAccepted : undefined,
            isMatchUpdated: this.isMatchUpdated != null ? +this.isMatchUpdated : undefined,
            isMatchDeleted: this.isMatchDeleted != null ? +this.isMatchDeleted : undefined,
            match: this.match && {
                ...this.match,
                isMatchUpdated: this.match.isMatchUpdated != null ? +this.match.isMatchUpdated : undefined,
                isMatchDeleted: this.match.isMatchDeleted != null ? +this.match.isMatchDeleted : undefined,
                isProducer: this.match.isProducer != null ? +this.match.isProducer : undefined,
                isDiffuser: this.match.isDiffuser != null ? +this.match.isDiffuser : undefined
            }
        };
    }
}
