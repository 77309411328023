import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Pagination } from 'src/app/shared/model/list-item.model';
import { MainService } from 'src/app/shared/services/main.services';
import { ShowStatus } from '../enums/show-status.enum';
import { SelectedFilter } from './../../../shared/model/list-item.model';
import { Globals } from './../../../_configs/globals';
import { Show, ShowContact, ShowFavorites } from './../model/show.model';
import { Classification } from '../model/classification.model';

@Injectable({
    providedIn: 'root'
})
export class ShowService extends MainService {
    constructor(
        protected httpClient: HttpClient,
        protected globals: Globals,
        private accountService: AccountService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private translate: TranslateService,
        private router: Router
    ) {
        super(httpClient, globals);
    }

    getShows(filters?: SelectedFilter[], pagination?: Pagination, sort?: any, search?: any): Observable<Show[]> {
        // TODO : filters etc...
        let endpoint = this.uri + this.globals.endpoints.show.all;

        if (filters || pagination || sort || search) {
            endpoint += '?';
        }

        if (filters && filters.length) {
            endpoint += this.formatGetFilters(filters);
            endpoint += pagination ? '&' : '';
        }
        if (pagination) {
            endpoint += this.formatPagination(pagination);
        }
        if (sort) {
            // TODO
            endpoint += '&sort=' + encodeURIComponent(sort);
        }
        if (search) {
            endpoint += '&search=' + encodeURIComponent(search);
        }

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) pagination.total = data[ 'total' ];
                return data[ 'shows' ].map((show) => new Show(show));
            })
        );
    }

    getShow(id: number): Observable<Show> {
        const endpoint = this.uri + this.globals.endpoints.show.main + '/' + id;
        return this.httpClient.get<Show>(endpoint).pipe(map((data) => new Show(data)));
    }

    extractArtistList(show: Show): string {
        let result = show.artists.length ? show.artists[ 0 ].name : '';
        result += show.artists.length > 1 ? ` ${this.translate.instant('ET')} ${show.artists.length - 1} ${this.translate.instant('OTHERS')}` : '';
        return result;
    }

    async createBlankShowAndRedirectToForm() {
        const show = new Show({});
        show.setTranslatedProperty(this.translate.currentLang, 'title', this.translate.instant('NOUVELLE-OFFRE-SPECTACLE'));

        // status, duration
        show.statusId = ShowStatus.NON_SOUMIS;
        show.durationTotal = 0;
        show.isAvailable = true;
        // ratacher à  l'organisation courante
        show.organizationId = this.accountService.getCurrentCtxOrganizationId();
        // Appel Rest creation de Show puis redirect vers modify de ce show
        this.createShow(show).subscribe((res) => {
            const url = this.localizeRouter.translateRoute(`/shows-offers/${show.organizationId}/${res[ 'show' ][ 'id' ]}/modify`);
            this.router.navigate([ url ]);
        });
    }

    createShow(show?: Show): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.main;
        return this.httpClient.post(endpoint, this.cleanUpNullValues(show));
    }

    createShowContact(showId: number, showContact?: ShowContact): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowContactEndpoint(showId);
        return this.httpClient.post(endpoint, showContact);
    }

    getShowContacts(showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowContactsEndpoint(showId);
        return this.httpClient.get(endpoint);
    }

    modifyShowContact(showId: number, showContact?: ShowContact): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowContactEndpoint(showId);
        return this.httpClient.put(endpoint, showContact);
    }

    removeShowContact(showId: number, contactId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowContactDeleteEndpoint(showId, contactId);
        return this.httpClient.delete(endpoint);
    }

    modifyShow(show: Show): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.main;
        const updatedShow = new Show({ ...show });
        const payload = this.cleanUpNullValues(updatedShow);
        payload['producerId'] = payload['producerId'] || null;
        return this.httpClient.put(endpoint, payload);
    }

    getAudiences(showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowAudiencesEndpoint(showId);
        return this.httpClient.get(endpoint);
    }

    getArtists(showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowArtistsEndpoint(showId);
        return this.httpClient.get(endpoint);
    }

    getAllArtists(): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getAllArtists;
        return this.httpClient.get(endpoint);
    }

    getClassifications(showId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowClassificationsEndpoint(showId);
        return this.httpClient.get(endpoint);
    }

    getClassificationsList(): Observable<Classification[]> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowClassificationsListEndpoint;
        return this.httpClient
            .get<{ classifications: unknown[] }>(endpoint)
            .pipe(map((response) => response.classifications.map((classification) => new Classification(classification))));
    }

    modifyShowAudience(showId: number, organizationId: number, audiences: number[]): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.audience;
        const body = {
            showId: showId,
            organizationId: organizationId,
            audiences: audiences
        };
        return this.httpClient.put(endpoint, body);
    }

    modifyShowArtists(showId: number, organizationId: number, artists: number[]): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.artists;
        const body = {
            showId: showId,
            organizationId: organizationId,
            artists: artists
        };
        return this.httpClient.put(endpoint, body);
    }

    modifyShowApproval(approvalState): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.approval;
        return this.httpClient.put(endpoint, approvalState);
    }

    // todo: À changer lors de l'intégration des choix multiples de classification
    modifyClassification(showId: number, organizationId: number, classifications: any[]): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.classification;
        const body = {
            showId: showId,
            organizationId: organizationId,
            classifications: classifications
        };
        return this.httpClient.put(endpoint, body);
    }

    deleteShow(showId: number, ctxId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.getShowDeleteEndpoint(showId, ctxId);
        return this.httpClient.delete(endpoint);
    }

    updateShowInfo(showId: number, organisationId: number, oldValues: any, newValues: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.showInfoEndpoint;
        const body = {
            showId: showId,
            organizationId: organisationId,
            oldValues,
            newValues
        };
        return this.httpClient.put(endpoint, body);
    }

    getShowFavorites(showId: number, organizationId?: number, networkIds?: number[]): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.show.getShowFavoritesEndpoint(showId);

        let params = new HttpParams();

        if (organizationId && organizationId !== this.globals.NO_ORGA) {
            params = params.append('organizationId', organizationId.toString());
        }

        if (networkIds && networkIds.length > 0) {
            params = params.append('networkIds', networkIds.toString());
        }

        return this.httpClient.get(endpoint, { params });
    }

    createShowFavorites(showId: number, favorite: ShowFavorites): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.show.editShowFavoritesEndpoint(showId);
        return this.httpClient.post(endpoint, favorite);
    }

    updateShowIsMarketing(showId: number, showMarketingDto: ShowMarketingDto) {
        return this.httpClient.patch(this.uri + this.globals.endpoints.show.getShowMarketingEndpoint(showId), {
            ...showMarketingDto
        });
    }

    public toShows = (shows: Show[]): Show[] =>
        shows.map(
            (show: Show) =>
                new Show({
                    ...show,
                    itemUrl: `${this.translate.currentLang}/${this.translate.instant('ROUTES.shows-offers')}/${show.organizationId}/${show.id}`,
                    itemUrlExt: true
                })
        );
}

export interface ShowMarketingDto {
    isMarketed: number;
    organizationId: number;
}
