import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Organization } from '../model/organization.model';
import { OrganisationStatus } from '../enums/organization-status.enum';
export type UserOrganizationStatus = 'created' | 'deleted' | 'updated' | null;

@Injectable({ providedIn: 'root' })
export class OrganizationManagerService {
    public readonly userOrganizationLocalStorageKey = 'sp.userOrganizations';
    private userOrganizationsSubject: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>([]);
    private selectedUserOrganizationSubject: Subject<Organization> = new Subject<Organization>();
    private userOrganizationStatusSubject: BehaviorSubject<UserOrganizationStatus> = new BehaviorSubject<UserOrganizationStatus>(null);
    private _userOrganizations$: Observable<Organization[]> = this.userOrganizationsSubject.asObservable();

    public readonly userOrganizationStatus$: Observable<UserOrganizationStatus> = this.userOrganizationStatusSubject.asObservable().pipe(shareReplay(1));
    public readonly userOrganizations$: Observable<Organization[]> = this._userOrganizations$.pipe(
        filter((data: Organization[]) => !!data),
        shareReplay(1)
    );
    public readonly selectedUserOrganizationId$: Observable<number> = this.selectedUserOrganizationSubject.pipe(
        filter((item: Organization) => !!item),
        map((item: Organization) => item.id),
        shareReplay(1)
    );
    public readonly selectedUserOrganizationName$: Observable<string> = this.selectedUserOrganizationSubject.pipe(
        filter((item: Organization) => !!item),
        map((item: Organization) => item.getTranslatedProperty(this.translate.currentLang, 'name'))
    );
    public readonly selectedUserOrganization$: Observable<Organization> = this.selectedUserOrganizationSubject.asObservable().pipe(
        filter((data: Organization) => !!data),
        shareReplay(1)
    );
    public readonly hasValidOrganizations$: Observable<boolean> = this.selectedUserOrganizationSubject.asObservable().pipe(
        map((res: Organization) => !!res && res.statusId === OrganisationStatus.APPROUVE),
        shareReplay(1));
    constructor(private translate: TranslateService) {}

    public setUserOrganizationStatus = (newStatus: UserOrganizationStatus): void => {
        this.userOrganizationStatusSubject.next(newStatus);
    };
    public setUserOrganizations = (organizations: Organization[]): void => {
        this.userOrganizationsSubject.next(organizations);
    };

    public addUserOrganization = (organization: Organization): Organization => {
        const newOrganization = new Organization(organization);
        this.setUserOrganizations([...this.userOrganizationsSubject.value, newOrganization]);
        this.selectUserOrganization(newOrganization);
        return newOrganization;
    };

    public deleteUserOrganization = (id: number): void => {
        const organizations = [...this.userOrganizationsSubject.value];
        const index = organizations.findIndex((o: Organization) => o.id === id);
        if (index > -1) {
            organizations.splice(index, 1);
            this.setUserOrganizations(organizations);
            this.selectUserOrganization(organizations[0]);
        }
    };  

    public leaveUserOrganization = (id: number): void => {
        const organizations = [...this.userOrganizationsSubject.value];
        const index = organizations.findIndex((o: Organization) => o.id === id);
        if (index > -1) {
            organizations.splice(index, 1);
            this.setUserOrganizations(organizations);
            this.setLocalUserOrganizations(organizations);
        }
    }; 

    public selectUserOrganizationById = (id: number): void => {
        const organizations = [...this.userOrganizationsSubject.value];
        if (id === -1) {
            this.selectUserOrganization(organizations[0]);
            return;
        }
        const index = organizations.findIndex((o: Organization) => o.id === id);
        if (index > -1) {
            this.selectUserOrganization(organizations[index]);
        }
    };

    public selectUserOrganization = (organization: Organization): void => {
        this.selectedUserOrganizationSubject.next(organization);
    };

    public resetLocalUserOrganizations = (): void => {
        sessionStorage.removeItem(this.userOrganizationLocalStorageKey);
    };

    public setLocalUserOrganizations = (organizations: Organization[]): void => {
        if (!organizations) {
            return;
        }
        sessionStorage.setItem(this.userOrganizationLocalStorageKey, JSON.stringify(organizations));
    };

    public getLocalUserOrganizations = (): Organization[] => {
        const result = sessionStorage.getItem(this.userOrganizationLocalStorageKey);
        if (result) {
            return JSON.parse(result);
        }
    };

}
