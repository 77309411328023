<nav class="main-navigation" *ngIf="user" [ngClass]="{ dashboardTheme: isDashboardTheme }">
    <ul class="main-navigation__list">
        <li class="main-navigation__list-item" style="margin-top: 15px">
            <a [routerLink]="'/dashboard' | localize" routerLinkActive="active">
                <img [src]="isDashboardTheme ? 'assets/images/scene_logo_dashboard.svg' : 'assets/images/scene_logo.svg'" alt="" />
            </a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/dashboard' | localize" routerLinkActive="active">{{ 'TABLEAU-BORD' | translate }}</a>
            <span *ngIf="notificationCount > 0" class="notificationCount">{{ notificationCount }}</span>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/pro-meeting' | localize" routerLinkActive="active">{{ 'ESPACES-PRO' | translate }}</a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/shows-offers' | localize" routerLinkActive="active">{{ 'OFFRES-SPECTACLES' | translate }}</a>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/repertory' | localize" routerLinkActive="active">{{ 'REPERTOIRE' | translate }}</a>
        </li>

        <li class="main-navigation__list-item" *ngIf="isSceneProAdmin$ | async">
            <a
                class="main-navigation__link"
                nz-dropdown
                [nzDropdownMenu]="adminDropDown"
                [nzTrigger]="'click'"
                [nzPlacement]="'bottomRight'"
                [nzClickHide]="false"
                [(nzVisible)]="isAdminMenuVisible"               
                [nzOverlayStyle]="{ left: '100px' }"
                id="main-navigation-admin-link"
            >
                <span class="mr-2">{{ 'ADMIN' | translate }}</span>
                <i class="icon-chevron-down align-middle"></i>
            </a>
            <nz-dropdown-menu #adminDropDown="nzDropdownMenu">
                <sp-menu [classList]="['p-3']">
                    <ng-container *ngFor="let item of menu">
                        <sp-menu-item [classList]="['dropdown-menu-item--hoverable']" (clicked)="navigateTo([item.link], 'admin')">
                            {{ item.label | translate }}
                        </sp-menu-item>
                    </ng-container>
                </sp-menu>
            </nz-dropdown-menu>
        </li>
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="'/help' | localize" routerLinkActive="active">
                <div [ngClass]="['help-icon', isDashboardTheme ? 'help-icon--white' : '']"></div>
            </a>
        </li>
    </ul>

    <ul class="main-navigation__list">
        <li class="main-navigation__list-item">
            <a class="main-navigation__link" [routerLink]="['/shopping-cart'] | localize" routerLinkActive="active">
                <nz-badge [nzCount]="cartCount" class="cartCounter">
                    <i nz-icon aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path
                                [attr.fill]="isDashboardTheme ? '#FFFFFF' : '#1C1C1B'"
                                fill-rule="nonzero"
                                d="M6.792 11L5.336 4.813A3 3 0 0 0 2.416 2.5H1.5a1 1 0 0 0 0 2h.916a1 1 0 0 1 .973.771l1.637 6.958A1 1 0 0 0 6 13h11a1 1 0 0 0 .977-.79l1.5-7A1 1 0 0 0 18.5 4H8a1 1 0 0 0 0 2h9.263l-1.072 5h-9.4zm8.708 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm-8 0a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
                            />
                        </svg>
                    </i>
                </nz-badge>
            </a>
        </li>
        <li class="main-navigation__list-item" *appPermission="1; organizationType: 1">
            <a class="main-navigation__link" [routerLink]="['/account/my-shows'] | localize" routerLinkActive="active" id="main-navigation-spectacle-link">{{ 'MES-SPECTACLES' | translate }}</a>
        </li>
        <li class="main-navigation__list-item" *ngIf="hasValidOrganizations$ | async">
            <a class="main-navigation__link" [routerLink]="'/programmations' | localize" routerLinkActive="active">{{ 'MES-CALENDRIERS' | translate }}</a>
        </li>

        <div class="profile-account">
            <!-- Photo de profil -->
            <a
                nz-dropdown
                class="profile-account-btn"
                [nzDropdownMenu]="accountDropDown"
                [nzTrigger]="'click'"
                [nzPlacement]="'bottomRight'"
                [nzClickHide]="false"
                [(nzVisible)]="isVisible"
                [nzBackdrop]="true"
            >
                <img [src]="user.avatar || './assets/images/placeholder-membre.svg'" alt="avatar" />
                <div class="userInfos">
                    <span class="ml-2" [ngClass]="{ 'clr-white': isDashboardTheme }">{{ user.firstName }}</span>
                    <span class="ml-2 userOrganization" [ngClass]="{ 'clr-white70': isDashboardTheme }">{{ selectedUserOrganizationName$ | async }}</span>
                </div>
                <i class="icon-chevron-down align-middle" [ngClass]="{ 'clr-white': isDashboardTheme }" aria-hidden="true"></i>
            </a>
            <nz-dropdown-menu #accountDropDown="nzDropdownMenu">
                <sp-menu [classList]="['p-3']">
                    <sp-menu-item>
                        <span class="main-navigation__subtitle">{{ 'CONNECTE-EN-TANT-QUE' | translate }}</span>
                    </sp-menu-item>
                    <ng-container *ngFor="let organization of userOrganizations$ | async">
                        <sp-menu-item [classList]="['dropdown-menu-item--hoverable']" (clicked)="setCurrentCtxOrganizationId(organization.id)">
                            <i class="fa fa-check" style="position: absolute; left: 10px; padding-top: 4px" *ngIf="(selectedUserOrganizationId$ | async) === organization.id"></i>
                            {{ organization.getTranslatedProperty(lang, 'name') }}
                        </sp-menu-item>
                    </ng-container>
                    <sp-divider></sp-divider>
                    <sp-menu-item [classList]="['dropdown-menu-item--hoverable']" (clicked)="navigateTo(['/profile/info'], 'account')">
                        <i nz-icon aria-hidden="true" nzType="user" nzTheme="outline" class="mr-1"></i>
                        {{ 'MON-COMPTE' | translate }}
                    </sp-menu-item>
                    <ng-container *ngIf="selectedUserOrganizationId$ | async as selectedUserOrganizationId">
                        <sp-menu-item
                            [classList]="['dropdown-menu-item--hoverable']"
                            (clicked)="navigateTo(['/organization/', selectedUserOrganizationId, 'modify', 'coordonnees'], 'account')"
                            *ngIf="selectedUserOrganizationId != -1"
                        >
                            <i nz-icon aria-hidden="true" nzType="setting" nzTheme="outline" class="mr-1"></i>
                            {{ 'REGLAGES' | translate }}&nbsp;{{ selectedUserOrganizationName$ | async }}
                        </sp-menu-item>
                    </ng-container>
                    <sp-menu-item [classList]="['dropdown-menu-item--hoverable']" (clicked)="onLogout()">
                        <a>
                            <i nz-icon aria-hidden="true" nzType="logout" nzTheme="outline" class="mr-1"></i>
                            {{ 'DECONNEXION' | translate }}
                        </a>
                    </sp-menu-item>
                </sp-menu>
            </nz-dropdown-menu>
        </div>
    </ul>
</nav>
