<button
  class="ConnectionButton"
  (click)='onClick($event)'
  [disabled]='isLoading'
  [type]="type"
  [class.ConnectionButton__desable]='disable'
  [id]="id"
>
{{ text }}
</button>
