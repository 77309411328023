import { Globals } from 'src/app/_configs/globals';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { Venue } from '../../../model/venue.model';
import { VenueStatus } from './../../../enums/venue-status.enum';
@Directive()
export abstract class AbstractVenueFormComponent {
    venueValue: Venue;

    @Output()
    venueChange = new EventEmitter<Venue>();

    @Output()
    submitCallback = new EventEmitter<any>();

    @Input()
    get venue(): Venue {
        return this.venueValue;
    }
    set venue(value) {
        //Si le changement vient du composant parent (changement complet d'organisation)
        if (this.venueValue && value.id !== this.venueValue.id) {
            this.venueValue = new Venue(value);
            this.initForm();
        } else {
            this.venueValue = new Venue(value);
        }
        this.checkDisable();

        this.venueChange.emit(this.venueValue);
    }

    protected abstract initForm(): void;

    @Input()
    displayErrors: boolean;

    public formGroup: UntypedFormGroup;

    @Input()
    public isLoading: boolean;

    constructor(protected accountService: AccountService, protected globals: Globals) {}

    protected checkDisable() {
        if (this.formGroup && this.venue.isSubmitted && this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID) {
            this.formGroup.disable();
        } else if (this.formGroup) {
            this.formGroup.enable();
        }

        if (this.accountService.getCurrentCtxOrganizationId() !== this.globals.SCENE_PRO_ORGID) {
            this.formGroup?.get('wikidataId')?.disable();
            this.formGroup?.get('artsdataId')?.disable();
        }
    }

    public doSubmit(param?: any) {
        this.venueChange.emit(this.venueValue);
        this.submitCallback.emit(param);
    }

    public getFormControl = (formControlName: string, formGroupName?: string): UntypedFormControl => {
        if (formGroupName) {
            return this.formGroup['controls'][formGroupName]['controls'][formControlName] as UntypedFormControl;
        }
        return this.formGroup.get(formControlName) as UntypedFormControl;
    };
}
