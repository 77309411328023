export class FormErrorHandler {

  static getErrorByKey(errors: FormError[], inLoopCallBack: (errorsMessage: string, key: string) => void): void {

    for (const error of errors) {
      for (const key in error) {
        inLoopCallBack(error[key], key)
      }
    }

  }

}

export interface FormError {
  [field: string]: string;
}
