import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ErrorsHandler } from '@app/shared/error-handling/errors-handler';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {

  private readonly angularPlugin = new AngularPlugin();
  private readonly appInsights: ApplicationInsights | undefined;
  private readonly router = inject(Router);

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.APPINSIGHTS_INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
        samplingPercentage: environment.APPINSIGHTS_SAMPLING_PERCENTAGE,
        correlationHeaderDomains: ['scenepro.ca'],
        enableCorsCorrelation: true,
        extensions: [
          this.angularPlugin,
        ],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new ErrorsHandler()],
          },
        },
      },
    });

    this.appInsights.loadAppInsights();
  }

  trackEvent(name: string) {
    this.appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights.trackTrace({ message });
  }
}
