<div class="DatePrograms">
    <p class="DatePrograms_firstDate" *ngIf="isTourShowRangeFirstDate">{{ 'AVAILABLE-DATES' | translate }}</p>
    <div class="DatePrograms_programShowInIterval" *ngIf="routeData.tourShow && isTourShowInDateRange"></div>
    <sp-checkbox [(ngModel)]="selected" (ngModelChange)="onToggle()" *ngIf="selectable" [disabled]="loading"></sp-checkbox>


    <div class="programs" *ngIf="programs">
        <ng-container *ngFor="let item of programs | slice: 0:showMorePrograms; let i = index">
            <div
                (click)="selectProgram(item)"
                [ngClass]="[
                    'program-calendar-item',
                    'program-calendar-item--' + programStatus[item.statusId],
                    isDisabledForUser(item) || isShowExpired(item) ? 'disabled' : '',
                    isShowExpired(item) ? 'expired' : ''
                ]"
            >
                <div *ngIf="isShowExpired(item)" class="tag tag--black">{{ 'STATUS.OFFRE-EXPIREE' | translate }}</div>
                <span class="program-calendar-chip" *ngIf="item.isMatchUpdated || item.isMatchDeleted"></span>
                <span class="program-calendar-item__title">{{ item.show ? item.show.getTranslatedProperty(translateService.currentLang, 'title') : item.showCustom }}</span>
                <span class="mr-1" *ngIf="item.hour">{{ item.hour | date: 'HH' }}h{{ item.hour | date: 'mm' }}</span>
                <span class="program-calendar-item__venueName">{{ item.venue ? item.venue.getTranslatedProperty(translateService.currentLang, 'name') : item.venueCustom }}</span>
                <span class="program-calendar-item__venueAddress">{{ item.venue && item.venue.address ? item.venue.address.city : '' }}</span>
                <span *ngIf="item.tourId && item.organization" class="program-calendar-item__venueAddress">
                    {{ item.organization | translatable: 'name' }}
                </span>
                <span *ngIf="!item.tourId && item.diffuser" class="program-calendar-item__venueAddress">
                    {{ item.diffuser | translatable: 'name' }}
                </span>
                <i class="icon-world" aria-hidden="true" *ngIf="item.tourId"></i>
            </div>
        </ng-container>
        <div class="more-dates" *ngIf="showMorePrograms < programs.length" (click)="showMorePrograms = showMorePrograms + 3">
            <h5>
                {{ programs.length - showMorePrograms }}
                {{ (programs.length - showMorePrograms === 1 ? 'TOURS.AUTRE-DATE' : 'TOURS.AUTRES-DATES') | translate }}
            </h5>
            <svg width="20" height="20" viewBox="0 0 20 20">
                <path
                    fill-rule="nonzero"
                    fill="#BBB6B3"
                    d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                />
            </svg>
        </div>
    </div>

    <ng-container *ngIf="showPrograms">
        <app-tour-show-program *ngFor="let program of showPrograms; let i = index" [program]="program" (handleActionProgram)="actionProgram($event)"></app-tour-show-program>
    </ng-container>

    <div class="overlay" *ngIf="loading">
        <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
    </div>

    <app-modal
        (handleValidateModal)="handleValidateWarningDateModal()"
        (handleCancelModal)="handleCancelWarningDateModal()"
        [showModal]="showWarningDateModal"
        [cancelModalFooterText]="'ANNULER' | translate"
        [type]="'warning'"
        [validateModalFooterText]="'CHOISIR-UNE-DATE' | translate"
        [modalTitle]="'TOURS.DATES-EXTERIEUR-DE-LA-TOURNEE' | translate"
        [modalContent]="'TOURS.DESCRIPTION-DATES-EXTERIEUR-DE-LA-TOURNEE' | translate"
    ></app-modal>

    <app-modal
        (handleValidateModal)="handleValidateWarningTourShowDateModal()"
        (handleCancelModal)="handleCancelWarningTourShowDateModal()"
        [showModal]="showTourShowWarningModal"
        [cancelModalFooterText]="'ANNULER' | translate"
        [type]="'warning'"
        [validateModalFooterText]="'CHOISIR-UNE-DATE' | translate"
        [modalTitle]="'TOURS.DATE-OUTSIDE-TOUR-SHOW-AVAILABILITY' | translate"
        [modalContent]="'TOURS.DESCRIPTION-DATES-EXTERIEUR-DE-LA-TOURNEE' | translate"
    ></app-modal>
</div>
