import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { GetSiteLanguageOptions } from '../../../../../shared/enums/app-languages.enum';
import { Role } from '../../../../../shared/enums/roles.enum';
import { Globals } from '../../../../../_configs/globals';
import { ShowService } from '../../../services/show.service';
import { AbstractShowForm } from '../show-abstract-form/show-abstract-form.component';
import { OrganizationService } from '../../../../organization/services/organization.service';
import { ShowStatus } from '../../../enums/show-status.enum';
import { Show } from '../../../../show/model/show.model';
import { ImgUploadService, ImgUploadState, ImgUploadStatus } from '@app/shared/components/img-upload/img-upload.service';
import { richTextMaxLength } from '@app/shared/components/rich-text/rich-text-max-length';

@Component({
    selector: 'app-show-identification-form',
    templateUrl: './show-identification-form.component.html',
    styleUrls: ['./show-identification-form.component.scss']
})
export class ShowIdentificationFormComponent extends AbstractShowForm implements OnInit, OnChanges {
    @Input() isLoading: boolean;
    @Input() displayErrors: boolean;
    public readonly showStatus = ShowStatus;
    public uploadAction: string;
    public formGroup: UntypedFormGroup;
    public SCENE_PRO_ID = this.globals.SCENE_PRO_ORGID;
    organizations;
    languagesOptionList = GetSiteLanguageOptions();
    approvedArtists: Array<string> = [];
    public readonly isSceneProAdmin$: Observable<boolean> = this.accountService.hasRole(Role.ADMINISTRATOR, this.globals.SCENE_PRO_ORGID);
    public readonly imageConfig = this.global.images;
    public readonly maxDescriptionLength400: number;
    public readonly maxDescriptionLength1800: number;

    constructor(
        readonly global: Globals,
        private readonly showService: ShowService,
        private readonly translate: TranslateService,
        private readonly organizationService: OrganizationService,
        protected readonly accountService: AccountService,
        private imgUploadService: ImgUploadService
    ) {
        super(accountService, global);
        this.maxDescriptionLength400 = this.global.MAXDESCRIPTIONLENGHT400;
        this.maxDescriptionLength1800 = this.global.MAXDESCRIPTIONLENGHT1800;
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup?.controls) {
            Object.values(this.formGroup.controls).forEach(control => 
                this.displayErrors ? control.markAsTouched() : control.markAsUntouched()
    );
}
    }

    public get isSceneProAdmin(): boolean {
        return this.accountService.getCurrentCtxOrganizationId() === this.globals.SCENE_PRO_ORGID;
    }

    ngOnInit(): void {
        this.uploadAction = this.global.api.ENDPOINT + 'upload/show/main';
        zip(this.isSceneProAdmin$, this.organizationService.getProducers())
            .pipe(take(1))
            .subscribe(([isSceneProAdmin, producers]) => {
                this.organizations = producers;
                this.updateForm(isSceneProAdmin);
                this.showService.getAllArtists().subscribe((res) => {
                    this.approvedArtists = res['artists'].map((artist) => artist.name);
                    this.show.artists
                        .map((artist) => artist.name)
                        .forEach((artist) => {
                            if (this.approvedArtists.indexOf(artist) < 0) {
                                this.approvedArtists.push(artist);
                            }
                        });
                });
            });
    }

    protected initForm(): void {
        return;
    }

    displaySubmit() {
        return this.formGroup && !this.formGroup.disabled && this.show && (this.show.statusId === ShowStatus.SOUMIS || this.show.statusId === ShowStatus.APPROUVE);
    }

    displaySaveForLater() {
        return this.show && (this.show.statusId === ShowStatus.NON_SOUMIS || this.show.statusId === ShowStatus.REJETE || this.show.statusId === ShowStatus.EXPIRE);
    }

    doSubmit(param?: any) {
        const show = new Show({
            ...this.show,
            ...this.formGroup.value,
            artists: this.formGroup.value.artists ? this.formGroup.value.artists.map((name: string) => ({ name })) : this.show.artists
        });
        this.show.setTranslatedProperty(this.translate.currentLang, 'shortDescription', this.formGroup.value.shortDescription);
        this.show.setTranslatedProperty(this.translate.currentLang, 'longDescription', this.formGroup.value.longDescription);
        this.show.setTranslatedProperty(this.translate.currentLang, 'coProducer', this.formGroup.value.coProducer);

        if (this.formGroup.contains('title')) {
            this.show.setTranslatedProperty(this.translate.currentLang, 'title', this.formGroup.value.title);
        }

        if (this.formGroup.value.producerId) {
            show.producer = this.organizations.find((org) => org.id == this.formGroup.value.producerId);
        }
        this.show = show;
        super.doSubmit(param);
    }

    getSceneProAdmin() {
        return this.accountService.getCurrentCtxOrganizationId();
    }

    onUploadFileCallback($event) {
        this.formGroup.patchValue({
            image: $event
        });
    }

    private imgCurrentStatusHandler = (): void => {
        this.imgUploadService.currentStatus$.pipe(filter((x) => x.status !== ImgUploadStatus.INIT)).subscribe((state: ImgUploadState) => {
            switch (state.status) {
                case ImgUploadStatus.UPLOADING:
                    this.isLoading = true;
                    this.formGroup.get('image').setErrors({ required: true });
                    break;
                case ImgUploadStatus.DELETED:
                    this.isLoading = false;
                    this.formGroup.patchValue({ image: null });
                    break;
                case ImgUploadStatus.SUCCESS:
                case ImgUploadStatus.ERROR:
                    this.isLoading = false;
                    this.formGroup.get('image').setErrors(null);
                    break;
            }
            this.formGroup.get('image').updateValueAndValidity();
        });
    };

    private updateForm(isSceneProAdmin: boolean) {
        this.formGroup = this.show.getIdentificationFormGroup(this.translate.currentLang, this.global);
        if (!isSceneProAdmin) {
            this.formGroup.removeControl('organizationId');
        } else {
            this.formGroup.addControl('organizationId', new UntypedFormControl(this.show.organizationId, [Validators.required]));
        }
        this.imgCurrentStatusHandler();
    }
}
