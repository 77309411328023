<div class="advancedSearch">
    <form nz-form [formGroup]="formGroup" class="w-100">
        <div nz-row>
            <div nz-col class="searchbar mb-3 mb-md-0" [nzMd]="isTourAdmin ? 5 : 6">
                <nz-input-group>
                    <label for="show">{{ 'RECHERCHER-PAR-TITRE' | translate }}</label>
                    <input type="text" nz-input nzShowSearch nzAllowClear name="show" id="show" nzSize="default" class="searchBarInput" formControlName="show" />
                </nz-input-group>
            </div>
            <div nz-col class="searchbar mb-3 mb-md-0" [nzMd]="isTourAdmin ? 5 : 6">
                <nz-input-group>
                    <label for="artist">{{ 'RECHERCHER-PAR-ARTISTE' | translate }}</label>
                    <input type="text" nz-input nzShowSearch nzAllowClear nzSize="default" name="artist" id="artist" class="searchBarInput" formControlName="artist" />
                </nz-input-group>
            </div>
            <div nz-col class="searchbar mb-3 mb-md-0" [nzMd]="isTourAdmin ? 5 : 6">
                <nz-input-group>
                    <label for="venue">{{ 'RECHERCHER-PAR-SALLE' | translate }}</label>
                    <input type="text" nz-input nzShowSearch nzAllowClear nzSize="default" name="venue" id="venue" class="searchBarInput" formControlName="venue" />
                </nz-input-group>
            </div>
            <div nz-col  [ngClass]="{'mb-3 mb-md-0': true, 'searchbar': !isProducer}" *ngIf="!isProducer; else diffuser" [nzMd]="isTourAdmin ? 5 : 6">
                <nz-input-group>
                    <label for="producer">{{ 'RECHERCHER-PAR-PRODUCTEUR' | translate }}</label>
                    <input type="text" nz-input nzShowSearch nzAllowClear nzSize="default" name="producer" id="producer" class="searchBarInput" formControlName="producer" />
                </nz-input-group>
            </div>
            <div nz-col class="pl-2 mb-3 mb-md-0" *ngIf="isTourAdmin" [nzMd]="isTourAdmin ? 4 : 6">
                <ng-container [ngTemplateOutlet]="diffuserSearchTpl"></ng-container>
            </div>
            <ng-template #diffuser>
                <div nz-col nzMd="6" class="pl-2 mb-3 mb-md-0">
                    <ng-container [ngTemplateOutlet]="diffuserSearchTpl"></ng-container>
                </div>
            </ng-template>
            <ng-template #diffuserSearchTpl>
                <nz-input-group>
                    <label for="diffusers" class="d-block">{{ 'RECHERCHER-PAR-DIFFUSEURS' | translate }}</label>
                    <ng-container [ngTemplateOutlet]="listTpl"></ng-container>
                    <ng-template #listTpl>
                        <nz-select
                            name="diffusers"
                            id="diffusers"
                            formControlName="diffusers"
                            class="w-100 c-diffusers-multiple-selection"
                            nzMode="multiple"
                            [nzShowArrow]="true"
                            nzPlaceHolder="Sélectionner une organisation"
                            [nzDropdownRender]="renderTemplate"
                            (nzOpenChange)="onOpenChange($event)"
                            [nzSuffixIcon]="isDiffuserMenuOpen ? 'up' : 'down'"
                        >
                            <ng-container *ngFor="let item of diffusers$ | async; let i = index">
                                <nz-option [nzLabel]="item.itemTitle" [nzValue]="item.itemId"></nz-option>
                            </ng-container>
                        </nz-select>
                        <ng-template #renderTemplate>
                            <nz-spin *ngIf="isLoading"></nz-spin>
                          </ng-template>
                    </ng-template>
                </nz-input-group>
            </ng-template>
        </div>
    </form>
</div>
<ng-template #loadingTpl>
    <nz-spin></nz-spin>
</ng-template>
