import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { ShowStatus } from 'src/app/concepts/show/enums/show-status.enum';
import { ListItem, SelectedFilter } from 'src/app/shared/model/list-item.model';
import { ShowService } from '../../services/show.service';
import {
  Filter,
  FilterValue,
  Pagination,
  Sort,
} from './../../../../shared/model/list-item.model';
import { Show } from './../../model/show.model';
import { Globals } from '../../../../_configs/globals';
import { RowTypes } from '../../../../shared/enums/row-types.enum';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { AuthService } from 'src/app/concepts/account/services/auth/auth.service';
import { Observable, forkJoin } from 'rxjs';
import { NetworkService } from 'src/app/concepts/organization/services/network.service';
import { OrganizationService } from 'src/app/concepts/organization/services/organization.service';
import {
  Organization,
  Network,
} from 'src/app/concepts/organization/model/organization.model';
import { ReportsService } from '../../../reports/reports.service';
import { zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ShowAdvancedSearchComponent } from './show-advanced-search/show-advanced-search.component';
import { ShowOfferSearchData, tagShowOfferSearch } from 'src/app/data-layer';
import { mergeMap } from 'rxjs-compat/operator/mergeMap';
import { HttpClient } from '@angular/common/http';
import { stringShortener } from 'src/app/shared/utils/string-shortener';

@Component({
  selector: 'app-shows-offers',
  templateUrl: './show-list.component.html',
  styleUrls: ['./show-list.component.scss'],
})
export class ShowListComponent implements OnInit {
  user: PxUser;

  showList: Show[];

  itemList: ListItem[] = [];

  leftSideFilters: Filter[] = [];

  sorts: Sort[] = [];

  pagination: Pagination = new Pagination();

  searchText: string;
  private showOfferSearchData: ShowOfferSearchData = {};

  private selectedFilters: SelectedFilter[] = [];

  lang: string;

  isLoading: boolean;

  contextOrgId: number;
  contextOrg: Organization = new Organization({});
  contextNetworkIds;

  defaultFilters: SelectedFilter[] = [
    {
      field: 'statusIds',
      value:
        this.accountService.getCurrentCtxOrganizationId() ===
        this.globals.SCENE_PRO_ORGID
          ? ''
          : '3,5',
    },
    {
      field: 'isAvailable',
      value:
        this.accountService.getCurrentCtxOrganizationId() ===
        this.globals.SCENE_PRO_ORGID
          ? ''
          : 1,
    },
    {
      field: 'isPaid',
      value:
        this.accountService.getCurrentCtxOrganizationId() ===
        this.globals.SCENE_PRO_ORGID
          ? ''
          : 1,
    },
    {
      field: 'withOrg',
      value: 1,
    },
  ];
  advancedSelectedFilters: SelectedFilter[] = [];
  listType: RowTypes = RowTypes.SHOW;
  isFavorites = false;
  isDownloadFavoritesLoading = false;

  constructor(
    private showService: ShowService,
    private accountService: AccountService,
    private globals: Globals,
    private authService: AuthService,
    private organizationService: OrganizationService,
    private networkService: NetworkService,
    private translate: TranslateService,
    private reportService: ReportsService,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.user = this.authService.User;
    this.lang = this.translate.currentLang;

    this.accountService.currentOrganizationChange.subscribe(() => {
      this.initialization();
    });

    this.initialization();
  }

   getshow(tag = false) {
    this.isLoading = true;
    sessionStorage.setItem('depot_pagination', JSON.stringify(this.pagination));
    // rest call with filters & pagination
    const allFilters = [
      ...this.defaultFilters,
      ...this.selectedFilters,
      ...this.advancedSelectedFilters,
      { field: 'orgIdfavorite', value: this.contextOrgId },
      { field: 'networkIds', value: this.contextNetworkIds?.networks },
      { field: 'userIdfavorite', value: 1 },
    ];



    this.showService
      .getShows(allFilters, this.pagination, null, this.searchText)
      .subscribe((shows: Show[]) => {

        this.showList = shows;
        this.itemList = shows.map(show => show.toListItem(this.lang));
        this.showList.map( (show: Show) => {

          const item = this.itemList.find( item => item.itemId === show.id);

          item.itemSubtitle = this.showService.extractArtistList(show);
          item.itemUrl = `/shows-offers/${show.organizationId}/${show.id}`;

          if(show.favorite.userFavorite || show.favorite.networkFavorite || show.favorite.organizationFavorite){

              item.banners.push({
                iconBackgroundColor: '#1702f8',
                iconName: 'favorite-icon.svg'
              })

            }

        });
        this.isLoading = false;
      });

    if (tag) {
      tagShowOfferSearch(this.showOfferSearchData, this.httpClient);
    }
  }

  initFilter(networks: Network[]) {
    this.selectedFilters = [];
    this.leftSideFilters = [];

    const allFilter = new Filter();
    allFilter.filterValues = [];
    allFilter.filterValues.push({
      filterValueName: this.translate.instant('TOUS'),
      filterValue: '',
      selected: true,
    } as FilterValue);

    const myFavorites = new Filter();
    myFavorites.filterField = 'favorites';
    myFavorites.filterName = this.translate.instant('MES-FAVORIS');
    myFavorites.filterValues = [];
    myFavorites.filterValues.push(
      { filterValueName: this.translate.instant('Moi'), filterValue: [1, null] } // userId
    );

    // organizationId
    if (this.contextOrg) {
      myFavorites.filterValues.push({
        filterValueName: stringShortener(this.contextOrg.getTranslatedProperty(
          this.lang,
          'name'
        ), 15)  + ' (' +  this.translate.instant('MY-TEAM') + ')',
        filterValue: [2, null],
      });
    }

    for (const network of networks) {
      myFavorites.filterValues.push({
        filterValueName: stringShortener( network.getTranslatedProperty(
          this.translate.currentLang,
          'name'
        ), 15 )+ ' (' +  this.translate.instant('MY-NETWORK') + ')',
        filterValue: [3, network.id],
      });
    }

    this.leftSideFilters = [allFilter, myFavorites];
    this.selectedFilters = [{ field: undefined, value: '' }];
    this.addRideauFilter();
  }

  addRideauFilter() {
    // Filtres sur le status pour l'utilisateur RIDEAU
    if (
      this.accountService.getCurrentCtxOrganizationId() ===
      this.globals.SCENE_PRO_ORGID
    ) {
      const statusFilter = new Filter();
      statusFilter.filterField = 'statusId';
      statusFilter.filterName = this.translate.instant('SHOW-STATUS');
      statusFilter.filterValues = Object.keys(ShowStatus)
        .filter((val) => isNaN(Number(val)))
        .map((key) => {
          return {
            filterValueName: this.translate.instant('STATUS.' + key),
            filterValue: ShowStatus[key],
          } as FilterValue;
        });

      //
      const favFilter = this.leftSideFilters.pop();
      this.leftSideFilters.push(statusFilter);
      this.leftSideFilters.push(favFilter);
    }
  }

  intitSort() {
    const sort1 = new Sort(),
      sort2 = new Sort(),
      sortByPromoted = new Sort();
    sort1.sortName = 'Status';
    sort1.sortValue = 'statusId';
    sort2.sortName = 'Date de creation';
    sort2.sortValue = 666;
    sortByPromoted.sortName = 'pomoted';
    sortByPromoted.sortValue = 'isPromoted';
    this.sorts = [sortByPromoted, sort1, sort2];
  }
  initPagination() {
    this.pagination = new Pagination(0, 16);
    const session =  JSON.parse(sessionStorage.getItem('depot_pagination'));
    if (session) {
      Object.assign(this.pagination, session);
    }
  }

  onFilterChange(event: SelectedFilter) {
    if (event.field == 'favorites') {
      let selectedFilter = new SelectedFilter();
      const [type, parameter] = event.value;
      switch (type) {
        case 1:
          selectedFilter = { field: 'favUserId', value: this.user.id };
          break;
        case 2:
          selectedFilter = { field: 'favOrgId', value: this.contextOrgId };
          break;
        case 3:
          selectedFilter = {
            field: 'favNetworkId',
            value: parameter,
          };
          break;

        default:
          break;
      }
      this.selectedFilters = [selectedFilter];
      this.isFavorites = true;
    } else {
      this.selectedFilters = [event];
      this.isFavorites = false;
    }

    this.getshow(true);
  }

  onAdvancedFilterChange(event: Parameters<ShowAdvancedSearchComponent['filtersChange']['emit']>[0]) {

    // On remet à la page 1 (si recherche manuelle)
    if (!event.rechercheSauvegardee) {
      this.pagination.offset = 0;
    }
    this.advancedSelectedFilters = event.filters;
    this.showOfferSearchData = event.dataLayer;
    this.getshow(!event.rechercheSauvegardee);
  }

  onSearchChange(event: string) {
    this.searchText = event;
    this.showOfferSearchData.terme = event;
    this.getshow(true);
  }

  onSortChange(event: any) {
    this.getshow();
  }

  onPageChange(event: Pagination) {
    this.pagination.offset = event.offset;
    this.getshow();
  }

  exportFavoriteShows() {
    this.reportService.generateMemberFavoritesReport([
      ...this.defaultFilters,
      ...this.selectedFilters,
      ...this.advancedSelectedFilters,
    ]);
  }

  private initialization() {
    this.contextOrgId = this.accountService.getCurrentCtxOrganizationId();
    this.networkService.getNetworksByOrganization(this.contextOrgId).subscribe( netIds => {
      this.contextNetworkIds = netIds
    })
    this.initPagination();

    zip(
      this.organizationService.getOrganization(this.contextOrgId),
      this.networkService.getNetworksByOrganization(this.contextOrgId),
      this.networkService.getAllNetworks()
    )
      .pipe(
        tap(([organisation]) => {
          this.contextOrg = organisation;
        }),
        map(([organisation, networksIds, networks]) => {
          return  networksIds.networks.map(id => networks.find(net => net.id == id)).reverse() ;
        })
      )
      .subscribe((networks) => {
        this.initFilter(networks);
        this.intitSort();
        this.initPagination();
        if (!sessionStorage.getItem('showAdvFilters')) {
          this.getshow();
        }
      });
  }
}
