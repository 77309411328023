import { Component, Input, HostBinding, ViewChild, ContentChild, TemplateRef, AfterContentInit, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';


let unique = 0;

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styles: [
        `
            :host.warning /deep/ .ant-select-selector {
                border: 2px solid #faad14 !important;
            }

            :host.warning /deep/ .ant-select-selector:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-select-selector {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-select-selector:hover {
                border: 2px solid #f5222d !important;
            }

            :host /deep/ .ant-select-focused .ant-select-selector {
                border: 2px solid #1702f8 !important;
                box-shadow: none !important;
            }

            :host /deep/ .ant-select-selection-search-input {
                border: none !important;
                padding-top: 7px !important;
                padding-right: 25px !important;
            }

            :host /deep/ .ant-select-selection-search-input::placeholder {
                line-height: 20px;
                color: #1C1C1B !important;
            }

            :host /deep/ .ant-select-selection-placeholder {
                line-height: 20px;
                color: #1C1C1B !important;
                text-align: left !important; 
                float: left;
            }

            :host.with-prefix /deep/ nz-select {
                display: inline-block;
                margin-left: 1em;
                margin-right: 1em;
                width: auto;
                min-width: 60px;
            }

            :host /deep/ .anticon.anticon-search svg {
                background: url('/assets/images/icon-chevron-down.svg') 54% -5px no-repeat #fff;
                transform: rotate(180deg);
                path { 
                    display: none;
                }
            }

            p.error {
                color: rgb(226, 0, 108) !important;
                margin-top: 5px;        
            }
        `
    ]
})
export class SelectComponent<T, U> implements ControlValueAccessor, AfterContentInit, OnChanges {
    private onChange: (value: U) => void;
    private onTouched: () => void;
    @ContentChild(TemplateRef, { static: true }) optionTemplate!: TemplateRef<any>;
    @Input() options: Array<T> | Record<string, Array<T>>;
    @Input() disabled: boolean;
    @Input() validateStatus = '';
    @Input() labelProperty = 'label';
    @Input() valueProperty = 'id';
    @Input() placeholder: string;
    @Input() withClear = true;
    @Input() withSearch = true;
    @Input() isRequired = false;
    @Input() showSearch = false;
    @Input() customLabel: (option: T) => string;
    @HostBinding('class.warning') get classWarning(): boolean {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError(): boolean {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled(): boolean {
        return this.disabled;
    }
    id = `SelectComponent-${unique++}`;
    selected: U;
    @ViewChild('prefix', { static: true }) prefix: unknown;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            this.setDisabledState(changes['disabled'].currentValue);
        }
    }

    ngAfterContentInit() {
        return;
    }
    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    writeValue(propertyValue: U): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: U) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    hasGroups(): boolean {
        return !(this.options instanceof Array);
    }
}
