import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ErrorsHandler } from 'src/app/shared/error-handling/errors-handler';
import { AuthService } from '../../concepts/account/services/auth/auth.service';
import { Globals } from '@app/_configs/globals';
import { IndexedDBStorage } from '../../shared/services/indexedDB.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ErrorsHandler]
})
export class LoginComponent implements OnInit {

  connectionForm: UntypedFormGroup;

  isLoading = false;

  constructor(
    private auth: AuthService,
    private translate: TranslateService,
    private route: Router,
    private formGroupBuilder: UntypedFormBuilder,
    private errorHandlerService: ErrorsHandler,
    private globals: Globals,
    private indexedDBStorage: IndexedDBStorage
    ) {}


  ngOnInit(){
    this.initConnectionForm();
  }

  resetPasswordClick() {
    this.route.navigate([this.translate.currentLang,'forgot-password']);
  }

  async login(event){
    event.stopPropagation();
    if(this.connectionForm.valid){
      const {email, password} = this.connectionForm.value;

      this.isLoading = true;

      await this.auth.loginV2({email, password}).toPromise()
      .then( _ => {
        this.indexedDBStorage.clearObjectStore(this.indexedDBStorage.userMemberShipsStore);
        this.route.navigate([this.translate.currentLang,'dashboard'])
      })
      .catch(err => {
        this.errorHandlerService.handleError(err)
      });

      this.isLoading = false;


    } else {
      this.connectionForm.markAllAsTouched();

    }
  }

  private initConnectionForm(){
    this.connectionForm = this.formGroupBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.globals.emailRegex), Validators.minLength(3)]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    })
  }
}
