import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-connection',
  templateUrl: './button-connection.component.html',
  styleUrls: ['./button-connection.component.scss']
})
export class ButtonConnectionComponent implements OnInit {

  @Input() buttonType: 'Primary' | 'Secondary' = 'Primary';

  @Input() text = '';

  @Input() id: string | null = null;

  @Input() isLoading = false;

  @Input() type = 'button'

  @Input() disable: boolean = false;

  @Output() click: EventEmitter<any> = new EventEmitter();

  @HostBinding('class.primary') get primary() { return this.buttonType === 'Primary'};

  @HostBinding('class.secondary') get secondary() {return this.buttonType === 'Secondary'};

  constructor() { }

  ngOnInit() {
  }

  onClick(event){
    this.click.emit(event);
  }
}
